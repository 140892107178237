<template>
    <v-container fluid>
        <template>
            <v-row>
                <v-col cols="6" class="pb-1 pt-0">
                    <v-breadcrumbs :items="itemsBreadcrumbs" class="pl-0 pt-0 pb-1">
                        <template v-slot:item="{ item }">
                            <v-breadcrumbs-item
                            :to="item.href"
                            :disabled="item.disabled"
                            >
                            {{ item.text.toUpperCase() }}
                            </v-breadcrumbs-item>
                        </template>
                    </v-breadcrumbs>
                </v-col>
                <v-col cols="6" class="pb-1 pt-0 h-100">
                    <office-login></office-login>
                </v-col>
            </v-row>            

            <v-row>
                <v-col cols="9" class="pt-1">
                    <span class="headline font-weight-medium">Recepción de transferencia</span>
                </v-col>
                <v-col cols="3" class="pt-1">
                    <v-btn dark color="#023145" width="140" class="float-right ml-3" @click="handleReceiveTransfer">
                        Registrar
                    </v-btn>
                    <v-btn dark color="red" class="float-right" @click="handleCancelReceiveTransfer">
                        Cancelar
                    </v-btn>                    
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12" class="pt-1 pb-1">
                    <v-card>
                        <v-data-table
                            :headers="headers"
                            :items="detail"
                            :items-per-page="10"
                            class="elevation-1 w-table"
                            height="65vh"
                            :single-select="true"
                        >
                            <template v-slot:top>
                                <span  class="ml-3 pt-3 pb-3 subtitle-1 font-weight-bold w-title-table">
                                    Lista de productos
                                </span>
                                <v-divider></v-divider>
                            </template>

                            <template v-slot:item.actions="{item}">
                                <v-btn v-if="isVerified(item)"
                                    small 
                                    dark 
                                    color="green" 
                                    @click="handleVerifyProduct(item)" 
                                    class="float-right"
                                    :disabled="item.status == '1'">
                                    <v-icon size="12" class="mr-3">fas fa-check</v-icon>
                                        Verificado
                                </v-btn>
                                <v-btn v-else
                                    small 
                                    dark 
                                    color="grey lighten-1" 
                                    @click="handleVerifyProduct(item)" 
                                    class="float-right"
                                    :disabled="item.status == '1'">
                                        Por Verificar
                                </v-btn>
                            </template>
                        </v-data-table>
                    </v-card>
                </v-col>
            </v-row>
        </template>

        <v-navigation-drawer
            v-model="drawer_accesory"          
            width="400"
            app
            temporary
            right
            class="wost-drawer"
        >
            <div class="navigation-head">
                <span>Verificación de cantidad</span>                         
            </div>

            <div class="pl-3 pt-3 pr-3" style="height: 80vh;" v-if="product">
                <template>
                    <v-row>
                        <v-col cols="12">
                            <v-card outlined>
                                <v-card-title class="pb-2 pt-2">
                                    <template>
                                        <v-row>
                                            <v-col cols="12" class="pt-1 pb-1">
                                                <span class="subtitle-2">
                                                    {{ product.description }}
                                                </span>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="12" class="pt-1 pb-1">
                                                <v-icon color="red" size="16" class="mr-1">
                                                    fas fa-boxes
                                                </v-icon>
                                                <span class="subtitle-2">
                                                    Cantidad: {{ product.sent_quantity }}
                                                </span>
                                            </v-col>
                                        </v-row>
                                    </template>
                                </v-card-title>
                            </v-card>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12">
                            <v-card>
                                <v-card-text>
                                    <v-container class="pt-0 pb-0">
                                        <v-row>
                                            <v-col cols="12">
                                                <v-text-field v-model="formSelect.received_quantity" 
                                                    ref="received_quantity"
                                                    label="Cantidad buen estado" 
                                                    type="number" 
                                                    outlined 
                                                    hide-details>
                                                </v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="12">
                                                <v-text-field v-model="formSelect.received_quantity_bad" 
                                                    ref="received_quantity_bad"
                                                    label="Cantidad mal estado" 
                                                    type="number" 
                                                    outlined 
                                                    hide-details>
                                                </v-text-field>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="6">
                            <v-btn dark color="red" block @click="handleCancelAddVerifyProduct">
                                Cancelar
                            </v-btn>
                        </v-col>
                        <v-col cols="6">
                            <v-btn dark color="green" block @click="handleAddVerifyProduct">
                                Guardar
                            </v-btn>
                        </v-col>
                    </v-row>

                </template>
            </div>

        </v-navigation-drawer>

        <v-snackbar
            v-model="show_message_products"            
            color="error"
            :timeout="3000"
            :top="'top'"
        >
            Debe verificar las cantidades de productos transferidos
            <v-btn
            dark
            text
            @click="show_message_products = false"
            >
                Cerrar
            </v-btn>
        </v-snackbar>
    </v-container>
</template>
<script>
import {mapState, mapActions} from 'vuex';
import router from '@/router/index';
import OfficeLogin from '../../../components/auth/OfficeLogin';

export default {
    name: 'ReceptionTransfer',
    components: {
        OfficeLogin
    },
    data() {
        return {
            itemsBreadcrumbs: [
                {
                    text: 'Logística',
                    disabled: true,
                    href: '',
                },
                {
                    text: 'Transferencias',
                    disabled: false,
                    href: '/app/logistica/transferencias',
                }
            ],
            headers: [
                { text: 'CATEGORIA', value: 'category' },
                { text: 'PRODUCTO', value: 'description' },
                { text: 'MARCA', value: 'brand' },
                { text: 'CANTIDAD', value: 'sent_quantity' },
                { text: '', value: 'actions' },
            ],
            drawer_imeis: false,
            drawer_accesory: false,
            product: null,
            products: [],
            formSelect: {
                imeis_rank: false,
                received_quantity: null,
                imei_start: null,
                imei_end: null,
                imei_single: null,
                imei_list: null,
                imeis_rank_bad: false,
                received_quantity_bad: null,
                imei_start_bad: null,
                imei_end_bad: null,
                imei_single_bad: null,
                imei_list_bad: null
            },
            show_message_products: false
        }
    },
    computed: {
        ...mapState('transfers', [
            'transfer',
            'detail'
        ]),
        ...mapState('inventories', [
            'list_imeis'
        ]),
    },
    methods: {
        ...mapActions('transfers', [
            'receive'
        ]),
        ...mapActions('inventories', [
            'getListImeis'
        ]),
        handleChangeTypeImei() {
            this.formSelect.imei_start = null
            this.formSelect.imei_end = null
            this.formSelect.imei_single = null
            this.formSelect.imei_list = null
            this.formSelect.received_quantity = null
        },
        handleChangeTypeImeiBad() {
            this.formSelect.imei_start_bad = null
            this.formSelect.imei_end_bad = null
            this.formSelect.imei_single_bad = null
            this.formSelect.imei_list_bad = null
            this.formSelect.received_quantity_bad = null
        },
        handleInputImeiStart(value) {            
            let category = this.product.category
            if (value !== undefined && value !== null) {
                if (category == 'Celulares') {
                    if (value.length == 15) {
                        this.calculateImeiEnd(value)
                    }
                } else if (category == 'Chips') {
                    if (value.length == 19) {
                        this.calculateImeiEnd(value)
                    }
                }
            }                        
        },
        handleInputImeiStartBad(value) {            
            let category = this.product.category
            if (value !== undefined && value !== null) {
                if (category == 'Celulares') {
                    if (value.length == 15) {
                        this.calculateImeiEnd(value, 'bad')
                    }
                } else if (category == 'Chips') {
                    if (value.length == 19) {
                        this.calculateImeiEnd(value, 'bad')
                    }
                }
            }                        
        },
        calculateImeiEnd(value, option = 'good') {
            let quantity = 0
            if (option == 'good') {
                if (this.formSelect.received_quantity) {
                    quantity = parseInt(this.formSelect.received_quantity)
                }
                let start = parseInt(value)
                let end = start + quantity - 1
                this.formSelect.imei_end = end
            } else {
                if (this.formSelect.received_quantity_bad) {
                    quantity = parseInt(this.formSelect.received_quantity_bad)
                }
                let start = parseInt(value)
                let end = start + quantity - 1
                this.formSelect.imei_end_bad = end
            }
            
        },
        handleInputImeiSingle(value) {
            let category = this.product.category
            if (value !== undefined && value !== null) {
                if (category == 'Celulares') {
                    if (value.length == 15) {
                        this.addImeiList(value)
                    }
                } else if (category == 'Chips') {
                    if (value.length == 19) {
                        this.addImeiList(value)
                    }
                }
            }            
        },
        handleInputImeiSingleBad(value) {
            let category = this.product.category
            if (value !== undefined && value !== null) {
                if (category == 'Celulares') {
                    if (value.length == 15) {
                        this.addImeiList(value, 'bad')
                    }
                } else if (category == 'Chips') {
                    if (value.length == 19) {
                        this.addImeiList(value, 'bad')
                    }
                }
            }            
        },
        addImeiList(value_imei, option = 'good') {
            if (option == 'good') {
                if (this.formSelect.imei_list) {
                    this.formSelect.imei_list = this.formSelect.imei_list + ',' + value_imei
                } else {
                    this.formSelect.imei_list = value_imei
                }
                let list_imeis = this.formSelect.imei_list.split(',')
                this.formSelect.received_quantity = list_imeis.length
                this.formSelect.imei_single = null
            } else {
                if (this.formSelect.imei_list_bad) {
                    this.formSelect.imei_list_bad = this.formSelect.imei_list_bad + ',' + value_imei
                } else {
                    this.formSelect.imei_list_bad = value_imei
                }
                let list_imeis = this.formSelect.imei_list_bad.split(',')
                this.formSelect.received_quantity_bad = list_imeis.length
                this.formSelect.imei_single_bad = null
            }
            
        },
        clearAllData() {
            this.products = []
            this.clearForm()
        },
        closeForm() {
            this.drawer_imeis = false
            this.drawer_accesory = false
            this.clearForm()
        },
        clearForm() {
            this.product = null    
            this.formSelect.imeis_rank = false
            this.formSelect.received_quantity = null
            this.formSelect.imei_start = null
            this.formSelect.imei_end = null
            this.formSelect.imei_single = null
            this.formSelect.imei_list = null
            this.formSelect.imeis_rank_bad = false
            this.formSelect.received_quantity_bad = null
            this.formSelect.imei_start_bad = null
            this.formSelect.imei_end_bad = null
            this.formSelect.imei_single_bad = null
            this.formSelect.imei_list_bad = null
        },
        handleVerifyProduct(item) {
            this.product = item
            this.drawer_accesory = true
            let indexfounded = this.verifiedProduct(this.product);
            if (indexfounded >= 0) {
                this.getProductVerify(indexfounded);
            }
        },
        handleCancelAddVerifyProduct() {
            this.closeForm();
        },
        handleAddVerifyProduct() {
            let received_imeis = '';
            let lost_imeis = '';

            let received_quantity = 0;
            let received_quantity_bad = 0;
            if (this.formSelect.received_quantity) {
                received_quantity = parseFloat(this.formSelect.received_quantity);
            }
            if (this.formSelect.received_quantity_bad) {
                received_quantity_bad = parseFloat(this.formSelect.received_quantity_bad);
            }
            let total_item = received_quantity + received_quantity_bad;
            if (total_item != parseFloat(this.product.sent_quantity)) {
                this.$swal({
                    text: "La suma de las cantidades debe ser la cantidad transferida del producto",
                    icon: "error",
                    showCancelButton: false,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: "Aceptar"
                });
            } else {
                let item_detail = {
                    detail_id: this.product.detail_id,
                    "received_quantity": this.formSelect.received_quantity,
                    "lost_quantity": this.formSelect.received_quantity_bad,
                    "received_imeis": received_imeis,
                    "lost_imeis": lost_imeis
                }
                let index = this.verifiedProduct(item_detail);
                if (index > -1) {
                    this.products.splice(index, 1, item_detail);
                } else {
                    this.products.push(item_detail);
                }                

                this.closeForm();
            }
        },
        handleCancelVerifyProduct() {
            this.closeForm()
        },
        async handleReceiveTransfer() {
            if (this.products.length > 0) {
                let total_verified = 0;
                for (let item of this.detail) {
                    if (this.isVerified(item)) {
                        total_verified++;
                    }
                }
                if (total_verified != this.detail.length) {
                    this.$swal({
                        text: "Falta verificar productos.",
                        icon: "error",
                        showCancelButton: false,
                        confirmButtonColor: "#DD6B55",
                        confirmButtonText: "Aceptar"
                    });
                } else {
                    let transfer_data = {
                        id: this.transfer.id,
                        detail: {
                            detail: this.products
                        }
                    }
                    await this.receive(transfer_data)
                    this.clearAllData()
                }                
            } else {
                this.$swal({
                    text: "Debe verificar los productos.",
                    icon: "error",
                    showCancelButton: false,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: "Aceptar"
                });
            }
        },
        handleCancelReceiveTransfer(){
            router.push('/app/logistica/transferencias');
        },
        async productsImeis(product){
            let inventory = {
                id: product.inventory
            };

            let filters = {
                detail_id: product.detail_id
            };

            await this.getListImeis({
                inventory: inventory,
                filters: filters
            });
        },
        customTextImei(item){
            let text = item.imei_1
            
            return text
        },
        verifiedProduct(product) {
            let indexFounded = -1;
            if (this.products) {
                this.products.forEach( (element, index) => {
                    if (element.detail_id == product.detail_id ) {
                        indexFounded = index;
                    }
                } )
            }            
            return indexFounded;
        },
        getProductVerify(index) {
            let item = this.products[index];
            this.formSelect.received_quantity = item.received_quantity;
            if (item.received_imeis) {
                if (item.received_imeis.includes('-')) {
                    this.formSelect.imeis_rank = true;
                    let imeis = item.received_imeis.split('-');
                    this.formSelect.imei_start = imeis[0];
                    this.formSelect.imei_end = imeis[1];
                } else {
                    this.formSelect.imeis_rank = false;
                    this.formSelect.imei_list = item.received_imeis;
                }
            }
            this.formSelect.received_quantity_bad = item.lost_quantity;
            if (item.lost_imeis) {
                if (item.lost_imeis.includes('-')) {
                    this.formSelect.imeis_rank_bad = true;
                    let imeis = item.lost_imeis.split('-');
                    this.formSelect.imei_start_bad = imeis[0];
                    this.formSelect.imei_end_bad = imeis[1];
                } else {
                    this.formSelect.imeis_rank = false;
                    this.formSelect.imei_list_bad = item.lost_imeis;
                }
            }  
        },
        isVerified (detail) {
            let verified = false;
            for (let item of this.products) {
                if (item.detail_id == detail.detail_id) {
                    verified = true;
                }
            }
            return verified;
        }
    },
    mounted() {
        this.clearAllData()
    }
}
</script>

<style scoped>
.reception-title{
    font-size: 1rem !important;
}

.section-verify-quantity{
    height: 65vh;
}
</style>